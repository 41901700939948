import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentbd from '../components/ServiceDetailsbd/ServiceDetailsContentbd'
import RelatedServicesbd from '../components/ServiceDetailsbd/RelatedServicesbd'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Big Data  "
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContentbd />
            <RelatedServicesbd />
            <Footer />
        </Layout>
    );
}

export default Details