import React from 'react'
import ServiceSidebarbd from './ServiceSidebarbd'
import detailsbd from '../../assets/images/services/services-detailsbd.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentbd = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={detailsbd} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Big Data </span>
                            <h3>About this Service</h3>
                            <p>At Diamonds Consulting, we use our advanced analytics techniques to identify key patterns & insights in very large diverse data sets and help you drive business decisions and benefits directly from your own as well as external data sources. </p>
                            <p>We have helped many customers implement a fast-track & agile business value identification, experimentation & exploration process that empowers them to realize tangible business value and uplifts. We do this via a variety of analytical use cases based on your business & industry.  We not only bring industry use cases but also architectural frameworks & accelerators to implement the use case in an agile & repeatable way. </p>
                            
                            
                            
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>3 Reusable Assets</li>
                                            <li>8 Big Data Projects Implemented</li>
                                            <li>40+ Industry Use Cases</li>
                                            <li>10+ years of experience </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>With our experience & architectural know-how we can help you define high value use cases in your industry/sector, identify data needs, do a fit-gap analysis within your data environments to find what is needed VS what is available already. Create an implementation plan and execute it within 6-8 weeks for each use case. In this way, we help you realize business value in shorter iterative cycles use case by use case. In our experience this is the best approach for Big Data projects. Let us help you on your big data journey in a faster time to value track!
</p>
                            <h3>Application Areas</h3>
                            <p> Related Industries/Application Domains</p>
                            <div className="row">
                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-hospital"></i>
                                    </div>
                                        Insurance
                                    </div>
                            </div>



                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-investment"></i>
                                    </div>
                                        Logistics
                                    </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-house"></i>
                                    </div>
                                        Manufacturing
                                    </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-house"></i>
                                    </div>
                                        Health Care

                                    </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-factory"></i>
                                    </div>
                                        Banking
                                    </div>
                            </div>
                            <div className="col-lg-5 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-tracking"></i>
                                    </div>
                                        Telecommunications
                                    </div>
                                </div>
                            </div>
                        

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>Oracle</span></li>
                                <li><span>Teradata</span></li>
                                <li><span>DB2</span></li>
                                <li><span>Netezza</span></li>
                                <li><span>MS Azure</span></li>
                                <li><span>AWS</span></li>
                                <li><span>Tableau</span></li>
                                <li><span>OAC</span></li>
                                <li><span>Qlikview</span></li>
                                <li><span>Microstrategy</span></li>
                                <li><span>BusinessObjects</span></li>
                                <li><span>R</span></li>
                                <li><span>Python</span></li>
                                <li><span>Jupyter Notebooks</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarbd />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentbd